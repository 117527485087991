<script setup lang="ts">
import {
  flagsConfig,
  type RemoteConfig,
  RewardSystemPromotions,
} from '@tn/shared';
import IconForYou from '@/assets/icons/for-you.svg?componentext';
import type { HeaderLink } from '~/types';
import NavWalletButton from '~/components/tn/nav/NavWalletButton.vue';
import NavPointsButton from '~/components/tn/nav/NavPointsButton.vue';
import NavCartButton from '~/components/tn/nav/NavCartButton.vue';
import NavMyAccountDropdown from '~/components/tn/nav/NavMyAccountDropdown.vue';
import { HEADERLINKS } from '~/constants/headerLinks';
import EVENTS, { SECTIONS } from '~/constants/events';

defineOptions({
  inheritAttrs: false,
});

const { $trackEvent, $getVariant } = useNuxtApp();
const attrs = useAttrs();
const links = computed(() => {
  const variant = $getVariant(flagsConfig.REWARD_SYSTEM_PROMOTIONS);

  return variant.value === RewardSystemPromotions.WebInbox
    ? HEADERLINKS.filter((link) => link.label !== 'Promotions')
    : HEADERLINKS;
});

const { canSeeRewardSystem } = useUserState();
const remoteConfig = useState<RemoteConfig>('remote-config');

const route = useRoute();

const linkClicked = (link: HeaderLink) => {
  const linkEventsMap: { [key: string]: string } = {
    Home: EVENTS.HOME_CLICKED,
    'Shop All': EVENTS.SHOP_ALL_CLICKED,
    'How it Works': EVENTS.HOW_IT_WORKS_CLICKED,
    Promotions: EVENTS.PROMOTIONS_CLICKED,
  };

  const eventProperties =
    link.label === 'Promotions'
      ? { page: route.meta.eventPageName, section: SECTIONS.PROMOTIONS_CTA }
      : { page: route.meta.eventPageName };

  $trackEvent(linkEventsMap[link.label], eventProperties);
};

const isForYouPageEnabled = computed(
  () => remoteConfig?.value?.forYouPage?.isEnabled
);
</script>

<template>
  <div class="max-w-app mx-auto w-full min-w-80 py-5">
    <nav
      class="bg-beige-400 relative mx-1 flex items-center rounded-[20px] border-0 py-2 pl-6 pr-4 md:border-b-4 md:border-l md:border-r md:border-t md:border-black"
      aria-label="Global"
    >
      <div class="flex flex-1">
        <div
          v-if="links?.length"
          v-bind="attrs"
          class="bg-beige-300 absolute inset-x-0 top-full z-10 mx-6 mt-1 flex flex-col items-center justify-between gap-y-8 rounded-[20px] border-b-4 border-l border-r border-t border-black px-3 pb-3 pt-8 md:static md:m-0 md:flex md:flex-row md:gap-x-5 md:border-0 md:bg-transparent md:p-0"
        >
          <div
            v-for="(link, index) of links"
            :key="index"
            class="relative mx-auto inline-flex"
          >
            <ULink
              :to="link.to"
              class="whitespace-nowrap text-sm font-normal transition hover:opacity-100"
              inactive-class="opacity-70"
              active-class="opacity-100 !font-medium"
              @click="linkClicked(link)"
            >
              {{ link.label }}
            </ULink>
          </div>
        </div>
      </div>

      <div
        class="absolute left-1/2 z-20 flex -translate-x-1/2 transform items-center justify-center"
      >
        <NuxtLink to="/" class="w-[100px] min-[855px]:w-[150px]">
          <img
            src="@/assets/taste-network-logo.svg"
            alt="Taste Network Logo"
            class="w-full object-contain"
          />
        </NuxtLink>
      </div>

      <div class="space-y-2">
        <div class="flex items-center justify-end">
          <ul
            class="text-tnNeutral-800/[65%] flex w-full items-center gap-2 space-x-4 text-sm font-medium"
            :class="{
              'justify-end': !isForYouPageEnabled,
              'justify-between': isForYouPageEnabled,
            }"
          >
            <li v-if="isForYouPageEnabled">
              <WebInboxWrapper>
                <template #default="{ unreadMessageCount, openModal }">
                  <button class="flex items-center gap-1.5" @click="openModal">
                    <IconForYou class="!mb-0 size-4" />
                    <span>Taste Exclusives</span>
                    <span
                      v-if="unreadMessageCount && unreadMessageCount > 0"
                      class="inline-flex items-center justify-center rounded-full bg-blue-500 px-1 text-xs font-medium text-neutral-50"
                    >
                      {{ unreadMessageCount }}
                    </span>
                  </button>
                </template>
              </WebInboxWrapper>
            </li>
            <li>
              <NavMyAccountDropdown />
            </li>
          </ul>
        </div>
        <div class="flex items-center justify-end gap-2">
          <NavPointsButton
            v-if="canSeeRewardSystem"
            span-class="hidden min-[855px]:block"
          />
          <NavWalletButton span-class="hidden min-[855px]:block" />
          <NavCartButton span-class="hidden min-[855px]:block" />
        </div>
      </div>
    </nav>
  </div>
</template>
